<template>
  <v-card flat>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Tempat Lahir :</td>
                      <td>
                        <v-card-actions class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="personal.tempat_lahir"
                            :disabled="formDisabled"
                          ></v-text-field>
                        </v-card-actions>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Agama :</td>
                      <td>
                        <v-select
                          :items="agamaList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.agama"
                          :disabled="formDisabled"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Tanggal Lahir :</td>
                      <td>
                        <v-text-field
                          class="ml-1"
                          type="date"
                          outlined
                          dense
                          hide-details
                          v-model="personal.tanggal_lahir"
                          :disabled="formDisabled || generatedNIP"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenis Kelamin :</td>
                      <td>
                        <v-select
                          :items="kelaminList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.kelamin"
                          item-text="label"
                          item-value="value"
                          :disabled="formDisabled || generatedNIP"
                        ></v-select>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Status Perkawinan :</td>
                      <td>
                        <v-select
                          :items="statusDiriList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.status_diri"
                          :disabled="formDisabled"
                        ></v-select>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="pa-0"
            >
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Alamat Tinggal
              </p>
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Alamat :</td>
                    <td colspan="3">
                      <v-textarea
                        outlined
                        dense
                        hide-details
                        v-model="personal.alamat"
                        rows="3"
                        :disabled="formDisabled"
                      ></v-textarea>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pa-0">
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Provinsi :</td>
                    <td>
                      <v-autocomplete
                        :items="provinsiList"
                        outlined
                        dense
                        hide-details
                        v-model="personal.provinsi"
                        :loading="provinsiLoading"
                        item-text="name"
                        item-value="kode_wilayah"
                        return-object
                        @change="addressChange('provinsi')"
                        :disabled="formDisabled"
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Kelurahan :</td>
                    <td>
                      <v-autocomplete
                        :items="kelurahanList"
                        :loading="kelurahanLoading"
                        outlined
                        dense
                        hide-details
                        v-model="personal.kelurahan"
                        item-text="name"
                        item-value="kode_wilayah"
                        return-object
                        @change="addressChange('kelurahan')"
                        :disabled="formDisabled"
                      ></v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pa-0">
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Kabupaten/Kota :</td>
                    <td>
                      <v-autocomplete
                        :items="kabupatenList"
                        outlined
                        dense
                        hide-details
                        v-model="personal.kabupaten"
                        :loading="kabupatenLoading"
                        item-text="name"
                        item-value="kode_wilayah"
                        return-object
                        @change="addressChange('kabupaten')"
                        :disabled="formDisabled"
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">RT/RW :</td>
                    <td>
                      <v-card-actions class="pa-0">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.rt"
                          :disabled="formDisabled"
                        ></v-text-field>
                        <v-text-field
                          class="ml-1"
                          outlined
                          dense
                          hide-details
                          v-model="personal.rw"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </v-card-actions>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="pa-0">
              <v-simple-table id="table-custom">
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Kecamatan :</td>
                    <td>
                      <v-autocomplete
                        :items="kecamatanList"
                        outlined
                        dense
                        hide-details
                        v-model="personal.kecamatan"
                        :loading="kecamatanLoading"
                        item-text="name"
                        item-value="kode_wilayah"
                        return-object
                        @change="addressChange('kecamatan')"
                        :disabled="formDisabled"
                      ></v-autocomplete>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Kode Pos :</td>
                    <td>
                      <v-text-field
                        outlined
                        dense
                        hide-details
                        v-model="personal.kode_pos"
                        :disabled="formDisabled"
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Pendidikan
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenjang :</td>
                      <td>
                        <v-autocomplete
                          :items="pendidikanList"
                          :loading="loadingPendidikan"
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.jenjang"
                          item-text="nama_pend"
                          item-value="kode_pend"
                          return-object
                          :disabled="formDisabled"
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tingkatan :</td>
                      <td>
                        <v-autocomplete
                          :items="levels"
                          :loading="loadingLevel"
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.level"
                          item-text="deskripsi_level"
                          item-value="pend_level_id"
                          :disabled="formDisabled"
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Bidang Studi :</td>
                      <td>
                        <v-autocomplete
                          :items="studiList"
                          :loading="loadingStudi"
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.bidang_studi"
                          item-text="nama_studi"
                          item-value="kode_studi"
                          return-object
                          :disabled="formDisabled"
                        ></v-autocomplete>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nama Sekolah :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.nama_sekolah"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tahun Lulus :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.thn_lulus"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Informasi Lain
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">No. KTP :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.no_KTP"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No. HP :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.hp_number"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">NPWP :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.NPWP"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TASPEN :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.no_taspen"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Email :</td>
                      <td>
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.email_address"
                          :disabled="formDisabled"
                        ></v-text-field>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import PublicService from "@/services/resources/public.service";
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  props: {
    generatedNIP: { type: Boolean, required: false, default: false },
    formDisabled: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      loadingPendidikan: false,
      loadingLevel: false,
      loadingStudi: false,
      kelurahanLoading: false,
      kecamatanLoading: false,
      kabupatenLoading: false,
      provinsiLoading: false,
      kelaminList: [
        {
          label: "Pria",
          value: "1"
        },
        {
          label: "Wanita",
          value: "2"
        }
      ],
      statusDiriList: ["Belum Kawin", "Kawin", "Duda", "Janda"],
      agamaList: ["Islam", "Katolik", "Protestan", "Hindu", "Budha"],
      pendidikanList: [],
      studiList: [],
      kelurahanList: [],
      kecamatanList: [],
      kabupatenList: [],
      provinsiList: [],
      levels: []
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal"
    }),
    pendidikanForm() {
      return this.personal.pendidikan.jenjang;
    }
  },
  watch: {
    pendidikanForm() {
      this.getStudiList();
    }
  },
  methods: {
    addressChange(type = "provinsi") {
      let nextType = "kabupaten";
      if (type == "provinsi") {
        this.kabupatenList = [];
        this.kecamatanList = [];
        this.kelurahanList = [];
        nextType = "kabupaten";
      } else if (type == "kabupaten") {
        this.kecamatanList = [];
        this.kelurahanList = [];
        nextType = "kecamatan";
      } else if (type == "kecamatan") {
        this.kelurahanList = [];
        nextType = "kelurahan";
      }
      this.getAddressList(nextType);
    },
    // Service
    async getPendidikanLevelLOV() {
      try {
        this.loadingLevel = true;
        await DataUtamaService.getPendidikanLevelLOV({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.levels = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingLevel = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getPendidikanList() {
      try {
        this.loadingPendidikan = true;
        await PublicService.pendidikanGetList({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.pendidikanList = data;
              this.getStudiList();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingPendidikan = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getStudiList() {
      try {
        this.loadingStudi = true;
        await PublicService.studiGetList({
          kode_pend: this.personal.pendidikan.jenjang?.kode_pend,
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.studiList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingStudi = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getAddressList(type = "provinsi") {
      let kode_wilayah = "";
      if (type == "provinsi") {
        kode_wilayah = "000000";
      } else if (type == "kabupaten") {
        kode_wilayah = this.personal.provinsi?.kode_wilayah;
      } else if (type == "kecamatan") {
        kode_wilayah = this.personal.kabupaten?.kode_wilayah;
      } else if (type == "kelurahan") {
        kode_wilayah = this.personal.kecamatan?.kode_wilayah;
      }
      try {
        this[type + "Loading"] = true;
        await PublicService[type + "GetList"]({
          kode_wilayah,
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[type + "List"] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[type + "Loading"] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getPendidikanList();
    this.getAddressList("provinsi");
    this.getAddressList("kabupaten");
    this.getAddressList("kecamatan");
    this.getAddressList("kelurahan");
    this.getPendidikanLevelLOV();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
